import { GetMyNotebooksState } from "../../../models";
import { GetMyNotebooksAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const getMyNotebooksReducer = (
  state: GetMyNotebooksState = { notebookData: null, loading: false, error: null },
  action: GetMyNotebooksAction
): GetMyNotebooksState => {
  switch (action.type) {
    case NotebookTypes.GET_MY_NOTEBOOKS_REQ:
      return {
        loading: true,
        notebookData: null,
        error: null,
      };
    case NotebookTypes.GET_MY_NOTEBOOKS_SUC:
      return {
        loading: false,
        error: null,
        notebookData: action.payload,
      };
    case NotebookTypes.GET_MY_NOTEBOOKS_FAIL:
      return {
        loading: false,
        error: action.error,
        notebookData: null,
      };

    default:
      return state;
  }
};
