import { GetAllDocumentsAction, GetMyUploadsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { dashboard_API } from "../../../api/notebookAPI";

export const getDashboardData =
  (setDashboardData: any) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      const { data } = await dashboard_API();
      setDashboardData(data.result.data);
    } catch (error: any) {
      return "failed";
    }
  };
