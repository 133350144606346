import { LoginAction, VerifyOtpAction } from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { verify_otp_api } from "../../../api/userAPI";
import Swal from "sweetalert";


export const verifyOTP =
  (reqData: any, navigate: any, setLoading: any) =>
    async (dispatch: Dispatch<LoginAction>): Promise<any> => {
      try {
        dispatch({ type: AuthTypes.LOGIN_REQ });
        const { data } = await verify_otp_api(reqData);
        console.log(data);


        if (data.code) {
          setLoading(false)
          // setTimeout(() => {
            navigate('/')
            localStorage.setItem("userInfo", JSON.stringify(data.result.data));
            Swal({
              title: `Welcome ${data?.result?.data?.user?.name}`,
              text: "Upload document and start asking questions !",
              // icon: "success",
              dangerMode: false,
            });
          // }, 5000)
        }

        dispatch({ type: AuthTypes.LOGIN_SUC, payload: data });
        dispatch({ type: AuthTypes.USER_DATA_SUC, payload: data.result.data });
      } catch (error: any) {
        setLoading(false)
        console.log('error occured');
        Swal({
          title: 'Oops...',
          text: 'Invalid OTP !',
        })
        // dispatch({
        //   type: AuthTypes.VERIFY_OTP_FAIL,
        //   error: error?.response?.data?.error,
        // });
        dispatch({
          type: AuthTypes.LOGIN_FAIL,
          error: error?.response?.data?.error,
        });
        // return alert(error?.response?.data?.message);
      }
    };