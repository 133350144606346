import { GetMyNotebooksAction } from "../../action-models";
import { NotebookTypes, CustomTypes } from "../../action-types";
import { Dispatch } from "react";
import { getMyNotebooks_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";

export const getMyNotebooks =
  () =>
  async (dispatch: Dispatch<GetMyNotebooksAction>): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.GET_MY_NOTEBOOKS_REQ });
      const { data } = await getMyNotebooks_API();
      const reveredDt = data.result.data.reverse()

      dispatch({ type: NotebookTypes.GET_MY_NOTEBOOKS_SUC, payload: data });
      console.log('inside getmy note');
      
      dispatch({
        type: CustomTypes.UPDATE_SELECTED_NOTEBOOK,
        payload: reveredDt[0],
      });
    } catch (error: any) {
      if (error?.response?.data?.code === "token_not_valid") {
        Swal({
          title: "Your session was expired! You have to login again.",
          dangerMode: false,
        }).then(() => {
          localStorage.removeItem("userInfo");
          window.location.replace("/auth");
        });
      }
      dispatch({
        type: NotebookTypes.GET_MY_NOTEBOOKS_FAIL,
        error: error?.response?.data?.error,
      });
      return "failed";
    }
  };
