import {SignUpState} from '../../../models';
import {SignUpAction} from "../../action-models";
import {AuthTypes}  from '../../action-types';

export const userSignupReducer = (
    state: SignUpState = { signUpData: null, loading: false, error: null },
  action: SignUpAction
):SignUpState =>{
    switch (action.type) {
        case AuthTypes.SIGNUP_REQ:
          return {
            loading: true,
            signUpData: null,
            error: null,
          };
        case AuthTypes.SIGNUP_SUC:
          return {
            loading: false,
            error: null,
            signUpData: action.payload,
          };
        case AuthTypes.SIGNUP_FAIL:
          return {
            loading: false,
            error: action.error,
            signUpData: null,
          };
    
        default:
          return state;
      }
}