import { CreateNotebookState } from "../../../models";
import { CreateNotebooksAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const createNotebookReducer = (
  state: CreateNotebookState = { createdNotebookData: null, loading: false, error: null },
  action: CreateNotebooksAction
): CreateNotebookState => {
  switch (action.type) {
    case NotebookTypes.CREATE_NOTEBOOKS_REQ:
      return {
        loading: true,
        createdNotebookData: null,
        error: null,
      };
    case NotebookTypes.CREATE_NOTEBOOKS_SUC:
      return {
        loading: false,
        error: null,
        createdNotebookData: action.payload,
      };
    case NotebookTypes.CREATE_NOTEBOOKS_FAIL:
      return {
        loading: false,
        error: action.error,
        createdNotebookData: null,
      };

    default:
      return state;
  }
};
