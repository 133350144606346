import axios from "axios";
import { getCookie, setCookie, generateDeviceId } from "../utils/deviceUtils";
const { REACT_APP_BACKEND_URL, REACT_APP_DEVICE_ID_COOKIE_NAME } = process.env;


const buildClient = () => {
  const userdata = JSON.parse(localStorage?.getItem("userInfo")!);
  const deviceIdCookie = getCookie(REACT_APP_DEVICE_ID_COOKIE_NAME || "");

  if (userdata?.token?.access) {
    return axios.create({
      baseURL: `${REACT_APP_BACKEND_URL}/api/`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${userdata?.token?.access}`,
        deviceId: deviceIdCookie,
      },
    });
  } else {
    return axios.create({
      withCredentials: true,
      baseURL: `${REACT_APP_BACKEND_URL}/api/`,
      headers: {
        deviceId: deviceIdCookie ,
      },
    });
  }
};
export default buildClient;
