import { GetAllDocumentState } from "../../../models";
import { GetAllDocumentsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const allDocumentReducer = (
  state: GetAllDocumentState = { documentData: null, loading: false, error: null },
  action: GetAllDocumentsAction
): GetAllDocumentState => {
  switch (action.type) {
    case NotebookTypes.GET_ALL_DOCUMENTS_REQ:
      return {
        loading: true,
        documentData: null,
        error: null,
      };
    case NotebookTypes.GET_ALL_DOCUMENTS_SUC:
      return {
        loading: false,
        error: null,
        documentData: action.payload,
      };
    case NotebookTypes.GET_ALL_DOCUMENTS_FAIL:
      return {
        loading: false,
        error: action.error,
        documentData: null,
      };

    default:
      return state;
  }
};
