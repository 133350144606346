import { UpdateSelectedDocAction } from "../../action-models";
import { CustomTypes } from "../../action-types";
import { UploadsData } from "../../../models";
import { Dispatch } from "react";

export const updateSelectedUploads =
  (selectedDoc: UploadsData | any) =>
  
  async (dispatch: Dispatch<UpdateSelectedDocAction>): Promise<any> => {
    try {
      dispatch({
        type: CustomTypes.UPDATE_SELECTED_DOCUMENT,
        payload: selectedDoc, 
      });
    } catch (error: any) {
      console.log(error);
    }
  };
