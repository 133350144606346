import buildClient from "./buildClient";
import {
  MyUploadsDataArr,
  MyNotebookssDataArr,
  UploadsDataObj,
  CreateNotebookDataObj,
  UpdateNotebookDataObj,
  CreateQuestionDataObj,
  ChatHistoryDataArr,
  DeleteDocumentObj,
  MyDocumentsDataArr,
  NotebookData,
  NotebookDataObj,
} from "../models";
import { Authconfig, config, Formconfig, AuthFormconfig } from "./config";
import Swal from "sweetalert";
const notebook = "notebook";
const chat = "chat";

export const getMyUploads_API = (notebook_id: string) =>
  buildClient().get<MyUploadsDataArr>(
    `${notebook}/my-uploads/${notebook_id}/`,
    config
  );

export const uploadDocument_API = (
  file: any,
  notebook_id: string,
  from: string,
  setProgress: any
) => {
  return buildClient().post<UploadsDataObj>(
    `${notebook}/upload-pdf/`,
    { file, notebook_id, from },
    // Formconfig,
    {
      ...Formconfig,
      onUploadProgress: (data) => {
        setProgress(Math.round((data.loaded * 100) / data?.total!));
      },
    }
  );
};

export const getMyNotebooks_API = () => {
  return buildClient().get<MyNotebookssDataArr>(
    `${notebook}/my-notebooks/`,
    config
  );
};

export const getMyDocuments_API = () => {
  return buildClient().get<MyDocumentsDataArr>(
    `${notebook}/my-documents/`,
    config
  );
};

export const createNotebooks_API = (notebook_name: string) => {
  return buildClient().post<CreateNotebookDataObj>(
    `${notebook}/create-notebook/`,
    { notebook_name },
    config
  );
};

export const checkDocumentStatus_API = (document_id: string) => {
  return buildClient().get<DeleteDocumentObj>(
    `${notebook}/document/${document_id}/`,
    config
  );
};

export const getNotebook_API = (notebook_id: string) => {
  return buildClient().get<NotebookDataObj>(
    `${notebook}/${notebook_id}/`,
    config
  );
};

export const deleteDocument_API = (document_id: string) => {
  return buildClient().delete<UploadsDataObj>(
    `${notebook}/delete-document/${document_id}/`,
    config
  );
};

export const deleteFailedDocument_API = (document_id: string) => {
  return buildClient().delete<UploadsDataObj>(
    `${notebook}/delete-failed-document/${document_id}/`,
    config
  );
};

export const updateNotebook_API = (
  notebook_id: string,
  notebook_name: string
) => {
  return buildClient().put<UpdateNotebookDataObj>(
    `${notebook}/update-notebook/`,
    { notebook_id, notebook_name },
    config
  );
};

export const askQuestion_API = (
  entity_id: string,
  question: string,
  entity_type: string,
  chat_id?: string
) => {
  if (entity_type === "shared_notebook") {
    return buildClient().post<CreateQuestionDataObj>(
      `${chat}/create/public-question/`,
      { entity_id, question, entity_type: "notebook", chat_id },
      config
    );
  } else {
    return buildClient().post<CreateQuestionDataObj>(
      `${chat}/create/question/`,
      { entity_id, question, entity_type, chat_id },
      config
    );
  }
};

export const getChatHistory_API = (entity_id: string, entity_type: string) => {
  if (entity_type === "notebook") {
    return buildClient().get<ChatHistoryDataArr>(
      `${chat}/get-notebook-chats/${entity_id}/`,
      config
    );
  }
  return buildClient().get<ChatHistoryDataArr>(
    `${chat}/get-document-chats/${entity_id}/`,
    config
  );
};

export const recheck_Failed_Doc_Status_API = (docId: string) => {
  return buildClient().get(
    `${notebook}/recheck-document-status/${docId}/`,
    config
  );
};

export const dashboard_API = () => {
  return buildClient().get(`${notebook}/my-dashboard-data/`, config);
};

export const remove_document_API = (
  notebook_id: string,
  document_id: string
) => {
  return buildClient().patch(`${notebook}/remove-document/`, {
    notebook_id,
    document_id,
  });
};

export const move_document_API = (
  notebook_id: string,
  document_ids: string[]
) => {
  return buildClient().post(`${notebook}/copy-document/`, {
    notebook_id,
    document_ids,
  });
};

export const delete_notebook_API = (notebook_id: string) => {
  return buildClient().delete(`${notebook}/delete-notebook/${notebook_id}/`);
};
