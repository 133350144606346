import { GetAllDocumentsAction, GetMyUploadsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { getMyDocuments_API } from "../../../api/notebookAPI";

export const getMyDocuments =
  () =>
  async (dispatch: Dispatch<GetAllDocumentsAction>): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.GET_ALL_DOCUMENTS_REQ });
      const { data } = await getMyDocuments_API();
      dispatch({ type: NotebookTypes.GET_ALL_DOCUMENTS_SUC, payload: data });

    } catch (error: any) {
      dispatch({
        type: NotebookTypes.GET_ALL_DOCUMENTS_FAIL,
        error: error?.response?.data?.error,
      });
      return "failed";
    }
  };
