import { DeleteDocumentsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { deleteFailedDocument_API } from "../../../api/notebookAPI";
import { UploadsData } from "../../../models";
import ErrorService from "../../../helpers/ErrorHandler";
import Swal from "sweetalert";

export const deleteFailedDocument =
  (document_id: string) =>
  async (
    dispatch: Dispatch<DeleteDocumentsAction>,
    getState: any
  ): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.DELETE_DOCUMENT_REQ });

      const { data } = await deleteFailedDocument_API(document_id);

      let unDeletedDocument =
        getState().myUploads?.uploadData?.result?.data?.filter(
          (el: UploadsData) => {
            if (el?.document_id !== document_id) {
              return el;
            }
            return null;
          }
        );

      if (unDeletedDocument) {
        getState().myUploads.uploadData.result.data = unDeletedDocument;
        dispatch({
          type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
          payload: getState().myUploads.uploadData,
        });
      }

      let unDeletedDocumentAllDoc =
      getState().allDocuments?.documentData?.result?.data?.filter(
        (el: UploadsData) => {
          if (el?.document_id !== document_id) {
            return el;
          }
          return null;
        }
      );

      if (unDeletedDocumentAllDoc) {
        getState().allDocuments.documentData.result.data = unDeletedDocumentAllDoc;
        dispatch({
          type: NotebookTypes.GET_ALL_DOCUMENTS_SUC,
          payload: getState().allDocuments?.documentData,
        });
      }

      dispatch({
        type: NotebookTypes.DELETE_DOCUMENT_SUC,
        payload: data,
      });
      ErrorService.handleResponse(data);

      Swal({
        title: "Document successfully Deleted!",
        text: "Your document has been successfully Deleted.",
        icon: "success",
        dangerMode: false,
      });

      return "true";
    } catch (error: any) {
      console.log(error);

      dispatch({
        type: NotebookTypes.DELETE_DOCUMENT_FAIL,
        error: error?.response?.data?.detail,
      });

      return "false";
    }
  };
