import { UploadDocumentState } from "../../../models";
import { UploadDocumentAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const uploadsDocumentReducer = (
  state: UploadDocumentState = { data: null, loading: false, error: null },
  action: UploadDocumentAction
): UploadDocumentState => {
  switch (action.type) {
    case NotebookTypes.UPLOAD_DOCUMENT_REQ:
      return {
        loading: true,
        data: null,
        error: null,
      };
    case NotebookTypes.UPLOAD_DOCUMENT_SUC:
      return {
        loading: false,
        error: null,
        data: action.payload,
      };
    case NotebookTypes.UPLOAD_DOCUMENT_FAIL:
      return {
        loading: false,
        error: action.error,
        data: null,
      };

    default:
      return state;
  }
};
