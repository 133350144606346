import React from "react";
import Button from "../Button";

interface ErrorFallbackProps {
  error: any;
  resetErrorBoundary: () => any;
}

const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  return (
    <div role="alert" className="fallback_error">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <Button
        title="Go Back"
        classes="small-btn blu-btn"
        onClick={resetErrorBoundary}
      />
    </div>
  );
};

export default ErrorFallback;
