import { useDispatch } from 'react-redux'
import styles from './ResetEmail.module.css'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useActions } from '../../hooks/useAction'
import CircularProgress from '@mui/material/CircularProgress'
import { useTypedSelector } from '../../hooks/useTypedSelector'

interface SetEmailProps {
    type : string,
    email : string
}

const SetEmail = ({type, email} : SetEmailProps) => {
    const [resetMail, setResetMail] = useState(false)
    const [formData, setFormData] = useState({})
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prev) => ({ ...prev, [name]: value }))
    }
    const {sendPassResetLink, verifyOTP} = useActions()
    // const { signUpData } = useTypedSelector((state) => state.userSignup);

 
    const handleSubmit = async (e:any) => {
        e.preventDefault()
        
        try {
            if(type === 'resetPass') {
                setLoading(true)
                console.log('sending');
                
                await sendPassResetLink(formData,navigate,setResetMail,setLoading)
            }
            else {
                setLoading(true)
                console.log('verifying');
                
                await verifyOTP(formData,navigate,setLoading)
            }
               
                
        } catch (err) {
            
            console.log(err)
        }
    }

    useEffect(() => {
        if(!email && type != 'resetPass'){
            navigate('/auth')
        }
        else{
            setFormData((prev) => ({ ...prev, ["email"]: email }))
        }
    }, [email])

    
    return (
        <div className={styles.ResetEmail}>
            {type === 'resetPass' ? (<h3 className={styles.title}>Password Recovery</h3>) : <h3 className={styles.title}>Verify Email</h3>}
            <div className={styles.reset_pass_card}>
                {resetMail ? (
                    <>
                        <FontAwesomeIcon style={{"color":"#F4F7FE"}} icon={faThumbsUp} size='xl'/>
                        <h4 style={{"color":"#F4F7FE"}}>Success</h4>
                        <h6>If an account is found related to the mail entered, a mail will be recieved in it. Please click the link in it to reset your password.</h6>
                    </>
                ) : (
                    <>
                        {type === 'resetPass' ? (<div><h6>Enter the email address associated with your account and we'll send you a link to reset your password.</h6>
                        <form action="">
                            <div className={styles.inputContainer}>
                                <label className={styles.infoLabel} htmlFor="email">Email*</label>
                                <input
                                    type="email"
                                    placeholder="abcd@qwerty.com"
                                    className={styles.resetInputField}
                                    name="email"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button className="btn btn-primary" type="submit" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}>Send Link</button>
                            </div>
                        </form></div>) : (<div><h6>Enter the OTP sent to your email account</h6>
                        <form action="">
                            <div className={styles.inputContainer}>
                                <label className={styles.infoLabel} htmlFor="OTP">OTP*</label>
                                <input
                                    type="text"
                                    placeholder="123456"
                                    className={styles.resetInputField}
                                    name="otp"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button className="btn btn-primary" type="submit" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSubmit(e)}>Verify OTP</button>
                            </div>
                        </form></div>)}
                    </>
                )}
                {loading && <div style={{ position: 'absolute', top: '100px', left: '50%' }}><CircularProgress /></div>}
            </div>
        </div >
    )
}

export default SetEmail
