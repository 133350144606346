export enum AuthTypes {
    SIGNUP_REQ = 'SIGNUP_REQ',
    SIGNUP_SUC = 'SIGNUP_SUC',
    SIGNUP_FAIL = 'SIGNUP_FAIL',

    LOGIN_REQ = 'LOGIN_REQ',
    LOGIN_SUC = 'LOGIN_SUC',
    LOGIN_FAIL = 'LOGIN_FAIL',

    
    USER_DATA_REQ = 'USER_DATA_REQ',
    USER_DATA_SUC = 'USER_DATA_SUC',
    USER_DATA_FAIL = 'USER_DATA_FAIL',

    RESET_PASS_LINK_REQ = 'RESET_PASS_LINK_REQ',
    RESET_PASS_LINK_SUC = 'RESET_PASS_LINK_SUC',
    RESET_PASS_LINK_FAIL = 'RESET_PASS_LINK_FAIL',

    RESET_PASSWORD_REQ = 'RESET_PASSWORD_REQ',
    RESET_PASSWORD_SUC='RESET_PASSWORD_SUC',
    RESET_PASSWORD_FAIL='RESET_PASSWORD_FAIL',

    VERIFY_OTP_REQ = 'VERIFY_OTP_REQ',
    VERIFY_OTP_SUC='VERIFY_OTP_SUC',
    VERIFY_OTP_FAIL='VERIFY_OTP_FAIL'
}