// getState().myUploads.uploadData?.result?.data?.push(data.result.data);
//         dispatch({
//           type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
//           payload: getState().myUploads.uploadData,
//         });

import { RemoveDocumentsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { remove_document_API } from "../../../api/notebookAPI";
import { UploadsData } from "../../../models";
import ErrorService from "../../../helpers/ErrorHandler";
import Swal from "sweetalert";

export const removeDocument =
  (notebook_id: string, document_id: string) =>
  async (
    dispatch: Dispatch<RemoveDocumentsAction>,
    getState: any
  ): Promise<any> => {
    try {
      console.log(notebook_id, document_id);

      const { data } = await remove_document_API(notebook_id, document_id);

      let unRemovedDocument =
        getState().myUploads?.uploadData?.result?.data?.filter(
          (el: UploadsData) => {
            if (el?.document_id !== document_id) {
              return el;
            }
            return null;
          }
        );

      getState().myUploads.uploadData.result.data = unRemovedDocument;

      dispatch({
        type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
        payload: getState().myUploads.uploadData,
      });

      Swal({
        title: "Document successfully removed!",
        text: "Your document has been successfully removed from this notebook.",
        icon: "success",
        dangerMode: false,
      });
    } catch (error: any) {
      console.log(error);
      Swal({
        title: "Feature not available",
        text: "This feature is not available with your current plan. Please visit the Plans page to subscribe to the relevant plan",
        dangerMode: true,
        buttons: ["Stay here", "Signup / Sign in"],
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = "/auth";
        }
      });
      return "false";
    }
  };
