import { CreateQuestionState } from "../../../models";
import { CreateQuestionsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const createQuestionReducer = (
  state: CreateQuestionState = { createQuestionData: null, loading: false, error: null },
  action: CreateQuestionsAction
): CreateQuestionState => {
  switch (action.type) {
    case NotebookTypes.CREATE_QUESTION_REQ:
      return {
        loading: true,
        createQuestionData: null,
        error: null,
      };
    case NotebookTypes.CREATE_QUESTION_SUC:
      return {
        loading: false,
        error: null,
        createQuestionData: action.payload,
      };
    case NotebookTypes.CREATE_QUESTION_FAIL:
      return {
        loading: false,
        error: action.error,
        createQuestionData: null,
      };

    default:
      return state;
  }
};
