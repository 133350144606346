import React, { ReactElement } from "react";
import Sidebar from "../../components/Sidebar";

import "./style.css";
import { useLocation } from "react-router-dom";

interface MainPageProps {
  children: ReactElement;
}

const Main = ({ children }: MainPageProps) => {
  const allowedRoutes = ["notebooks", "documents", "plans"];

  const loc = useLocation();
  const parts = loc.pathname.split("/");
  const match = parts[parts.length - 1];

  const checkPath = allowedRoutes.find((item) => item === match);

  return (
    <div className="dashboard_container">
      <Sidebar active={checkPath ? checkPath : "home"} />
      <main className="main_container">{children}</main>
    </div>
  );
};

export default Main;
