import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import GoogleButton from "react-google-button";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import styles from "./Auth.module.css";
import { useActions } from "../../hooks/useAction";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { CircularProgress } from "@mui/material";

interface FormData {
  name: string;
  email: string;
  password: string;
  password1: string;
}

const Auth = () => {
  const [signup, setSignup] = useState(false);
  const initialFormData: FormData = {
    email: "",
    name: "",
    password: "",
    password1: "",
  };
  const [formData, setFormData] = useState<FormData | any>(initialFormData);
  const [passVisiblity, setPassVisibility] = useState(false);
  const [pass2Visiblity, setPass2Visibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const { userdata } = useTypedSelector((state) => state.user);

  const navigate = useNavigate();
  const { userSignup, userLogin, userGoogleLogin, updateSelectedNotebook } =
    useActions();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault(); 
    updateSelectedNotebook(null);
    setFormError({});
    if (
      !formData.email.match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      )
    ) {
      return setFormError((prev: any) => ({ ...prev, ["validEmail"]: true }));
    }
    if (
      signup &&
      (Object.keys(formData).length !== 4 ||
        !formData.name ||
        !formData.email ||
        !formData.password ||
        !formData.password1)
    ) {
      for (const key in formData) {
        if (!formData[key])
          setFormError((prev: any) => ({ ...prev, [key]: true }));
      }
      return false;
    }

    if (!signup && (!formData.email || !formData.password)) {
      for (const key in formData) {
        if (!formData[key])
          setFormError((prev: any) => ({ ...prev, [key]: true }));
      }
      return null;
    }

    if (signup && formData.password !== formData.password1) {
      return setFormError((prev: any) => ({
        ...prev,
        ["passwordMatch"]: true,
      }));
    }
    if (signup) {
      if (formData.password.length < 8 || formData.password1.length < 8) {
        return setFormError((prev: any) => ({
          ...prev,
          ["passwordLength"]: true,
        }));
      }
    }

    try {
      setLoading(true);
      if (signup) {
        await userSignup(formData, setSignup, setLoading, navigate);
      } else {
        await userLogin(formData, navigate, setLoading);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePassVisibility = () => {
    const element = document.getElementById("password") as HTMLInputElement;
    if (element) {
      if (passVisiblity) {
        element.type = "password";
        setPassVisibility(false);
      } else {
        element.type = "text";
        setPassVisibility(true);
      }
    }
  };

  const handlePass2Visibility = () => {
    const element = document.getElementById("password1") as HTMLInputElement;
    if (element) {
      if (pass2Visiblity) {
        element.type = "password";
        setPass2Visibility(false);
      } else {
        element.type = "text";
        setPass2Visibility(true);
      }
    }
  };

  const handleGoogleButton = useGoogleLogin({
    onSuccess: async (data) => {
      try {
        setLoading(true);
        await userGoogleLogin({ code: data.code }, navigate, setLoading);
      } catch (err: any) {
        console.error(err);
      }
    },
    flow: "auth-code",
  });

  useEffect(() => {
    if (userdata?.user) navigate("/notebooks");
  }, [userdata]);

  return (
    <div className={styles.Auth}>
      <div className={styles.leftSide}>
        <div className={styles.container}>
          <h1 className={styles.title}>{signup ? "Sign Up" : "Sign In"}</h1>
          <h4 className={styles.descriptioin}>
            Enter your email and password to sign in!
          </h4>
          <div className="d-flex align-items-center">
            <div className={styles.seperator}> 
              <hr className={styles.diffSep} />
            </div>
            <div className={styles.short}>or</div>
            <div className={styles.seperator}>
              <hr className={styles.diffSep} />
            </div>
          </div>
          <div className="d-grid gap-2">
            <GoogleButton
              className={styles.googleButton}
              onClick={handleGoogleButton}
            />
          </div>

          <form action="">
            {signup && (
              <div className={styles.inputContainer}>
                <label className={styles.infoLabel} htmlFor="name">
                  Name*
                </label>
                <input
                  type="text"
                  style={formError.name ? { border: "1px solid red" } : {}}
                  placeholder="Mike Smith"
                  className={styles.infoInput}
                  name="name"
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            <div className={styles.inputContainer}>
              <label className={styles.infoLabel} htmlFor="email">
                Email*
              </label>
              {formError.validEmail && (
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginBottom: "0px",
                    textAlign: "start",
                  }}
                >
                  * Please enter a valid email
                </p>
              )}

              <input
                style={formError.email ? { border: "1px solid red" } : {}}
                type="email"
                placeholder="abcd@qwerty.com"
                className={styles.infoInput}
                name="email"
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.inputContainer}>
              <label className={styles.infoLabel} htmlFor="password">
                Password*
              </label>
              {formError.passwordLength && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "0px",
                    textAlign: "start",
                  }}
                >
                  * The password must consist of a minimum of 8 characters
                </p>
              )}
              <div className="d-flex align-items-center">
                <input
                  style={
                    formError.password || formError.passwordLength
                      ? { border: "1px solid red" }
                      : {}
                  }
                  type="password"
                  placeholder="Min. 8 characters"
                  className={styles.infoInput}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  required
                />
                {passVisiblity ? (
                  <FontAwesomeIcon
                    onClick={handlePassVisibility}
                    className={styles.faIcon}
                    icon={faEyeSlash}
                  />
                ) : (
                  <FontAwesomeIcon
                    onClick={handlePassVisibility}
                    className={styles.faIcon}
                    icon={faEye}
                  />
                )}
              </div>
            </div>

            {signup && (
              <div className={styles.inputContainer}>
                <label className={styles.infoLabel} htmlFor="password1">
                  Confirm Password*
                </label>
                {formError.passwordMatch && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "0px",
                      textAlign: "start",
                    }}
                  >
                    * Confirm password does'nt match with password
                  </p>
                )}

                <div className="d-flex align-items-center">
                  <input
                    type="password"
                    style={
                      formError.password1 || formError.passwordMatch
                        ? { border: "1px solid red" }
                        : {}
                    }
                    placeholder="Min. 8 characters"
                    className={styles.infoInput}
                    id="password1"
                    name="password1"
                    onChange={handleChange}
                    required
                  />
                  {pass2Visiblity ? (
                    <FontAwesomeIcon
                      onClick={handlePass2Visibility}
                      className={styles.faIcon}
                      icon={faEyeSlash}
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={handlePass2Visibility}
                      className={styles.faIcon}
                      icon={faEye}
                    />
                  )}
                </div>
              </div>
            )}

            {!signup && (
              <div className="mt-3 d-flex align-items-center">
                <input
                  type="checkbox"
                  id="select"
                  name="loggedIn"
                  value="True"
                />
                <label id={styles.checkboxLabel} htmlFor="loggedIn">
                  Keep me logged in.
                </label>
              </div>
            )}

            <div className="mt-3 d-flex justify-content-between align-items-center">
              <div>
                <p className={styles.textQuery}>
                  {signup
                    ? "Already have an account? "
                    : "Not registered yet? "}
                  <span
                    className={styles.textSpan}
                    onClick={() => {
                      setFormError({});
                      setSignup((prev) => !prev);
                      Array.from(document.querySelectorAll("input")).forEach(
                        (input) => (input.value = "")
                      );
                      for (const item in formData) {
                        setFormData((prev: any) => ({ ...prev, [item]: "" }));
                      }
                    }}
                  >
                    {signup ? "Log In" : "Create an account"}
                  </span>
                </p>
              </div>
              {!signup && (
                <p
                  className={styles.textSpan2}
                  onClick={() => navigate("/recover-password")}
                >
                  Forgot password
                </p>
              )}
            </div>

            <div className="d-grid gap-2">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  handleSubmit(e)
                }
              >
                {signup ? "Sign Up" : "Sign In"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.rightSide}>
        <a href="/" style={{ textDecoration: "none" }}>
          <h1 className={styles.heading}>PdfBot</h1>
        </a>
      </div>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "100px",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={80} thickness={5} />
        </div>
      )}
    </div>
  );
};

export default Auth;
