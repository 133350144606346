import { GetNotebookAction } from "../../action-models";
import { NotebookTypes, CustomTypes } from "../../action-types";
import { Dispatch } from "react";
import { getNotebook_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";

export const getNotebook =
  (notebook_id: string) =>
  async (dispatch: Dispatch<GetNotebookAction>): Promise<any> => {
    try {
      const { data } = await getNotebook_API(notebook_id);

      dispatch({
        type: CustomTypes.UPDATE_SELECTED_NOTEBOOK,
        payload: data.result.data,
      });
    } catch (error: any) {
      return "failed";
    }
  };
