import { AuthTypes } from "../../action-types";
interface UserData {
  token: {
    access: string;
    refresh: string;
  };
  user: {
    id: string;
    name: string;
    email: string;
  };
}
let userData: UserData;

if (typeof window !== "undefined") {
  const userInfoFromStorage: UserData = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")!)
    : null;
  userData = userInfoFromStorage;
}

export const userReducer = (
  state: any = { userdata: userData, loading: false, error: null },
  action: any
): any => {
  switch (action.type) {
    case AuthTypes.USER_DATA_REQ:
      return {
        error: null,
        loading: false,
        userdata: null,
      };

    case AuthTypes.USER_DATA_SUC:
      return {
        error: null,
        loading: false,
        userdata: action.payload,
      };

    case AuthTypes.USER_DATA_FAIL:
      return {
        error: null,
        loading: false,
        userdata: null,
      };
    default:
      return state;
  }
};
