import buildClient from "./buildClient";

const payment = "payments";
export const Get_Payment_Update_URL = (planId: string) => {
  return buildClient().post(`${payment}/generate_checkout_existing_url/`, {
    plan_id: planId,
  });
};

export const Update_Payment_Status = (planId: string) => {
  return buildClient().post(`${payment}/update_payment_status/`, {
    plan_id: planId,
  });
};

export const Get_Subscription_Details = () => {
  return buildClient().get(`${payment}/check_subscription_status/`);
};

export const get_plan_details_API = () => {
  return buildClient().get(`${payment}/get_all_plans/`);
};

export const sync_chargebee = () => {
  return buildClient().get(`${payment}/resync_chargebee/`)
}

export const Cancel_Plan = () => {
  return buildClient().post(`${payment}/cancel_subscription/`)
}

export const Downgrade_Subscription = (planId: string) => {
  return buildClient().post(`${payment}/downgrade_subscription/`, {plan_id: planId})
}