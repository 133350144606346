import styles from './ChangePass.module.css'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useActions } from '../../hooks/useAction'
import { CircularProgress } from '@mui/material'


interface FormData {
    password1: string;
    password2: string;
  }

const ChangePass = () => {
    const { uid, token } = useParams()
    const initialFormData: FormData = {
        password1: "",
        password2: "",
      };
      const [formData, setFormData] = useState<FormData>(initialFormData);
    const [passVisiblity, setPassVisibility] = useState(false)
    const [pass2Visiblity, setPass2Visibility] = useState(false)
    const [loading,setLoading] = useState(false)
    const [formError, setFormError] = useState<any>({})
    const navigate = useNavigate()
    const {resetPassword} = useActions()

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;
        setFormData((prev) => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async(e:any) => {
        e.preventDefault();

        if(formData.password1 !== formData.password2){
            return setFormError((prev: any) => ({ ...prev, ['passwordMatch']: true }));
        }
            if (formData.password1.length < 8 || formData.password2.length < 8) {
              return setFormError((prev: any) => ({ ...prev, ['passwordLength']: true }));
            }
      

        try {
            setLoading(true)
            await resetPassword(formData,uid,token,navigate,setLoading)
        } catch (err:any) {
            console.log(err)
        }
    }

    const handlePassVisibility = () => {
        const element = document.getElementById('password1') as HTMLInputElement;
        if (element) {
            if (passVisiblity) {
                element.type = "password";
                setPassVisibility(false);
            } else {
                element.type = "text";
                setPassVisibility(true);
            }
        }
    };
    
    const handlePass2Visibility = () => {
        const element = document.getElementById('password2') as HTMLInputElement;
        if (element) {
            if (pass2Visiblity) {
                element.type = "password";
                setPass2Visibility(false);
            } else {
                element.type = "text";
                setPass2Visibility(true);
            }
        }
    };
    
    return (
        <div className={styles.ChangePass}>
            <div className={styles.leftSide}>
                <div className="p-4">
                    <h3 className={styles.title}>Reset Password</h3>
                    <form className={styles.resetForm}>
                        <div className={styles.inputContainer}>
                            <label className={styles.infoLabel} htmlFor="password">Password*</label>
                            {formError.passwordLength && <p style={{ color: 'red', fontSize: '12px', marginBottom: '0px', textAlign: 'start' }}>* The password must consist of a minimum of 8 characters</p>}
                            <div className='d-flex align-items-center'>
                                <input
                                    type="password"
                                    style={formError.password1 ||formError.passwordLength ? { border: '1px solid red' }: {}}
                                    placeholder="Min. 8 characters"
                                    className={styles.infoInput}
                                    id='password1'
                                    name="password1"
                                    onChange={handleChange}
                                    required
                                />
                                {passVisiblity ? (<FontAwesomeIcon onClick={handlePassVisibility} className={styles.faIcon} icon={faEyeSlash} />) : (<FontAwesomeIcon onClick={handlePassVisibility} className={styles.faIcon} icon={faEye} />)}
                            </div>
                        </div>
                        <div className={styles.inputContainer}>
                            <label className={styles.infoLabel} htmlFor="password">Confirm Password*</label>
                            {formError.passwordMatch && <p style={{ color: 'red', fontSize: '12px', marginBottom: '0px', textAlign: 'start' }}>* Confirm password does'nt match with password</p>}
                            <div className='d-flex align-items-center'>
                                <input
                                    type="password"
                                    style={formError.password2 || formError.passwordMatch   ? { border: '1px solid red' } : {}}
                                    placeholder="Min. 8 characters"
                                    className={styles.infoInput}
                                    id='password2'
                                    name="password2"
                                    onChange={handleChange}
                                    required
                                />
                                {passVisiblity ? (<FontAwesomeIcon onClick={handlePass2Visibility} className={styles.faIcon} icon={faEyeSlash} />) : (<FontAwesomeIcon onClick={handlePass2Visibility} className={styles.faIcon} icon={faEye} />)}
                            </div>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Change Password</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={styles.rightSide}>
                <h1 className={styles.heading}>PdfBot</h1>
            </div>
            {loading && <div style={{ position: 'absolute', top: '100px', left: '50%' }}><CircularProgress /></div>}
        </div>

    )
}

export default ChangePass
