import {  LoginAction} from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { google_login_API } from "../../../api/userAPI";
import Swal from 'sweetalert'


export const userGoogleLogin =
  (reqData:any,navigate:any,setLoading:any) =>
  async (dispatch: Dispatch<LoginAction>): Promise<any> => {
    try {
      dispatch({ type: AuthTypes.LOGIN_REQ });
      const { data } = await google_login_API(reqData);
      localStorage.setItem("userInfo", JSON.stringify(data.result.data));
      Swal({
        title: `Welcome ${data?.result?.data?.user?.name}`,
        text: 'Upload document and start asking questions !',
        dangerMode: false,
      })
      if(data.code){
        setLoading(false) 
        navigate('/')}
      dispatch({ type: AuthTypes.LOGIN_SUC, payload: data });
      dispatch({ type: AuthTypes.USER_DATA_SUC, payload: data.result.data });
    } catch (error: any) {
      setLoading(false)
      dispatch({
        type: AuthTypes.LOGIN_FAIL,
        error: error?.response?.data?.error,
      });
      return Swal({
        title: "Google Signin failed !",
        dangerMode: false,
      });
    }
  };