import { GetChatHistorysAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { getChatHistory_API } from "../../../api/notebookAPI";

export const getChatHistory =
  (entity_id: string, entity_type: string) =>
  async (dispatch: Dispatch<GetChatHistorysAction>): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.GET_CHAT_HISTORY_REQ });
      const { data } = await getChatHistory_API(entity_id,entity_type);
      
      dispatch({ type: NotebookTypes.GET_CHAT_HISTORY_SUC, payload: data });
      return data.result.data;
    } catch (error: any) {
      dispatch({
        type: NotebookTypes.GET_CHAT_HISTORY_FAIL,
        error: error?.response?.data?.message,
      });
      return "failed";
    }
  };
