import React, { useState, useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress';


const LazyLoadingComp = () => {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <div className='LazyCompMain' style={{ width: '100%', height: '100vh' }}>
            <LinearProgress variant="determinate"  color="success" value={progress} />

            <img  src="https://media.istockphoto.com/id/1180848753/vector/brain-technology-top-view-vector-icon-template.jpg?s=612x612&w=0&k=20&c=ndt3agsofxke3Vo_JCR4oskmZywFuJg_RbcVamDdTxQ=" alt="" />
        </div>
    )
}

export default LazyLoadingComp
