import React, { ReactElement } from "react";

import "./style.css";

type ButtonProps = {
  classes: string;
  title: string;
  children?: ReactElement;
  onClick?: (e: any) => void;
  isDisable?: boolean;
};

const Button = ({ classes, title, children, onClick }: ButtonProps) => {
  return (
    <button type="button" className={classes} onClick={onClick}>
      <span className="btn-title">{title}</span>
      <span className="btn-icon">{children}</span>
    </button>
  );
};

export default Button;
