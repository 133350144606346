import styles from './ResetPass.module.css'
import React from 'react'
import SetEmail from '../../components/ResetEmail/ResetEmail'
import { useLocation } from 'react-router-dom';

const ResetPass = () => {
    const locat = useLocation();
    const email = locat.state?.data;
    return (
        <div className={styles.ResetPass}>
            <div className={styles.leftSide}>
                <SetEmail type = {'resetPass'} email={email}/>
            </div>
            <div className={styles.rightSide}>
                <h1 className={styles.heading}>PdfBot</h1>
            </div>
        </div>
    )
}

export default ResetPass
