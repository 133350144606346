// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_container {
    height: 100%;
    width: 100%;
    background: #272727;
}

.main_container {
    width: calc(100vw - 70px);
    height: 100vh;
    padding-left: 65px;
    padding-top: 30px;
}


@media screen and (max-width: 1600px) {
    .dashboard_container {
        padding-top: 0;
    }

    .main_container {
        width: 100%;
        height: 100%;
        padding-left: 50px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/main/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB;;;AAGA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,WAAW;QACX,YAAY;QACZ,kBAAkB;IACtB;AACJ","sourcesContent":[".dashboard_container {\n    height: 100%;\n    width: 100%;\n    background: #272727;\n}\n\n.main_container {\n    width: calc(100vw - 70px);\n    height: 100vh;\n    padding-left: 65px;\n    padding-top: 30px;\n}\n\n\n@media screen and (max-width: 1600px) {\n    .dashboard_container {\n        padding-top: 0;\n    }\n\n    .main_container {\n        width: 100%;\n        height: 100%;\n        padding-left: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
