import { CopyNotebookAction } from "../../action-models";
import { NotebookTypes, CustomTypes } from "../../action-types";
import { Dispatch } from "react";
import { move_document_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";
import { NotebookData } from "../../../models";
import { useActions } from "../../../hooks/useAction";


export const copyDocument =
  (notebook: any, document_ids: string[], updateSelectedNotebook: any, navigate: any = null, source: string = 'notebook') =>
    async (
      dispatch: Dispatch<CopyNotebookAction>,
      getState: any
    ): Promise<any> => {
      try {
        dispatch({ type: NotebookTypes.COPY_NOTEBOOK_REQ });

        const { data } = await move_document_API(
          notebook?.notebook_id,
          document_ids
        );

        dispatch({
          type: NotebookTypes.COPY_NOTEBOOK_SUC,
          payload: true,
        });
        Swal({
          title: "Document successfully copied!",
          text: `Your document has been successfully copied to the ${notebook?.notebook_name}`,
          icon: "success",
          dangerMode: true,
          buttons: ["Stay here", "Goto notebook"],

        }).then((Yes) => {
          if (Yes) {
            updateSelectedNotebook(notebook)
            if (source !== 'notebook') {
              console.log('yes go to notebook');
              console.log(notebook);
              navigate('/notebooks', {
                state : {data : notebook}
              })
              // updateSelectedNotebook(notebook)

            }

          }
        });
      } catch (error: any) {
        console.log(error);
        Swal({
          title: "Document coping failed!",
          text: "Something went wrong while coping your document.",
          icon: "failed",
          dangerMode: true,
        });
      }
    };
