import {  ResetPasswordAction} from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { reset_password_api } from "../../../api/userAPI";
import Swal from 'sweetalert'


export const resetPassword =
  (reqData:any,uid:any,token:any,navigate:any,setLoading:any) =>
  async (dispatch: Dispatch<ResetPasswordAction>): Promise<any> => {
    try {
      dispatch({ type: AuthTypes.RESET_PASSWORD_REQ });
      const { data } = await reset_password_api(reqData,uid,token);
       if(data.code){
        setLoading(false)
           navigate('/auth')
       }
      dispatch({ type: AuthTypes.RESET_PASSWORD_SUC, payload: data });
      return 'success'
    } catch (error: any) { 
      setLoading(false)
      dispatch({
        type: AuthTypes.RESET_PASSWORD_FAIL,
        error: error?.response?.data?.error,
      });
      return Swal({
        title: "Token expired",
        dangerMode: false,
      })
    }
  };