import { LoginAction } from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { login_API } from "../../../api/userAPI";
import Swal from "sweetalert";

export const userLogin =
  (reqData: any, navigate: any, setLoading: any) =>
  async (dispatch: Dispatch<LoginAction>): Promise<any> => {
    try {
      dispatch({ type: AuthTypes.LOGIN_REQ });
      const { data } = await login_API(reqData); 
      console.log(data);
      
      localStorage.setItem("userInfo", JSON.stringify(data.result.data));
      Swal({
        title: `Welcome ${data?.result?.data?.user?.name}`,
        text: "Upload document and start asking questions !",
        // icon: "success",
        dangerMode: false,
      });
      if (data.code) {
        navigate("/notebooks");
      }
      dispatch({ type: AuthTypes.LOGIN_SUC, payload: data });
      dispatch({ type: AuthTypes.USER_DATA_SUC, payload: data.result.data });
    } catch (error: any) {
      setLoading(false);
      console.log(error?.response?.data?.statusCode);
      
      if (error.response.data.message === "Email Id or password is incorrect") {
        return Swal({
          title: "Your password and email does'nt match !",
          // icon: "warning",
          dangerMode: false,
        });
      } else if (error?.response?.data?.statusCode === 401){
        navigate("/verify-email", {
          state : {data : reqData?.email}
        })
      }
       else {
        Swal({
          title: "Login Failed! Please try again.",
          // icon: "warning",
          dangerMode: false,
        });
      }

      dispatch({
        type: AuthTypes.LOGIN_FAIL,
        error: error?.response?.data?.error,
      });
    }
  };
