import {  ResetPassLinkAction} from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { sent_reset_password_email_api } from "../../../api/userAPI";
import Swal from "sweetalert";


export const sendPassResetLink =
  (reqData:any,navigate:any,setResetMail:any,setLoading:any) =>
  async (dispatch: Dispatch<ResetPassLinkAction>): Promise<any> => {
    try {
      dispatch({ type: AuthTypes.RESET_PASS_LINK_REQ });
      const { data } = await sent_reset_password_email_api(reqData);
       if(data.code){
        setLoading(false)
          setResetMail(true) 
          setTimeout(()=>{
              navigate('/auth')
          },5000)
       }
      dispatch({ type: AuthTypes.RESET_PASS_LINK_SUC, payload: data });
    } catch (error: any) {
      setLoading(false)
      console.log('error occured');
      Swal({
        title: 'Oops...',
        text: 'Please check your email !',
      })
      dispatch({
        type: AuthTypes.RESET_PASS_LINK_FAIL,
        error: error?.response?.data?.error,
      });
      // return alert(error?.response?.data?.message);
    }
  };