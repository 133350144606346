import { GetMyUploadsState } from "../../../models";
import { GetMyUploadsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const getMyUploadsReducer = (
  state: GetMyUploadsState = { uploadData: null, loading: false, error: null },
  action: GetMyUploadsAction
): GetMyUploadsState => {
  switch (action.type) {
    case NotebookTypes.GET_MY_DOCUMENTS_REQ:
      return {
        loading: true,
        uploadData: null,
        error: null,
      };
    case NotebookTypes.GET_MY_DOCUMENTS_SUC:
      return {
        loading: false,
        error: null,
        uploadData: action.payload,
      };
    case NotebookTypes.GET_MY_DOCUMENTS_FAIL:
      return {
        loading: false,
        error: action.error,
        uploadData: null,
      };

    default:
      return state;
  }
};
