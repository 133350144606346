import { LogoutAction } from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";

export const userLogout =
  () =>
  async (dispatch: Dispatch<LogoutAction>): Promise<any> => {
    try {
      localStorage.removeItem("userInfo");
      dispatch({ type: AuthTypes.USER_DATA_FAIL });
    } catch (error: any) {
      console.log(error);
    }
  };
