import {SentEmailResetPassState} from '../../../models';
import {ResetPassLinkAction} from "../../action-models";
import {AuthTypes}  from '../../action-types';

export const resetPasswordLinkReducer = (
    state: SentEmailResetPassState = { data: null, loading: false, error: null },
  action: ResetPassLinkAction
):SentEmailResetPassState =>{
    switch (action.type) {
        case AuthTypes.RESET_PASS_LINK_REQ:
          return {
            loading: true,
            data: null,
            error: null,
          };
        case AuthTypes.RESET_PASS_LINK_SUC:
          return {
            loading: false,
            error: null,
            data: action.payload,
          };
        case AuthTypes.RESET_PASS_LINK_FAIL:
          return {
            loading: false,
            error: action.error,
            data: null,
          };
    
        default:
          return state;
      }
}