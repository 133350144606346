import { SelectedNotebookState } from "../../../models";
import { UpdateSelectedNotebookAction } from "../../action-models";
import { CustomTypes } from "../../action-types";

export const selectedNotebookReducer = (
  state: SelectedNotebookState = { selectedNotedata: null },
  action: UpdateSelectedNotebookAction
): SelectedNotebookState => {
  switch (action.type) {
    case CustomTypes.UPDATE_SELECTED_NOTEBOOK:
      return {
        selectedNotedata: action.payload,
      };

    default:
      return state;
  }
};
