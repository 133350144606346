import { ChatHistoryState } from "../../../models";
import { GetChatHistorysAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const getChatHistoryReducer = (
  state: ChatHistoryState = { chatHistoryData: null, chatLoading: false, error: null },
  action: GetChatHistorysAction
): ChatHistoryState => {
  switch (action.type) {
    case NotebookTypes.GET_CHAT_HISTORY_REQ:
      return {
        chatLoading: true,
        chatHistoryData: null,
        error: null,
      };
    case NotebookTypes.GET_CHAT_HISTORY_SUC:
      return {
        chatLoading: false,
        error: null,
        chatHistoryData: action.payload,
      };
    case NotebookTypes.GET_CHAT_HISTORY_FAIL:
      return {
        chatLoading: false,
        error: action.error,
        chatHistoryData: null,
      };

    default:
      return state;
  }
};
