import { SignUpAction } from "../../action-models";
import { AuthTypes } from "../../action-types";
import { Dispatch } from "react";
import { signUp_API } from "../../../api/userAPI";
import Swal from 'sweetalert'

export const userSignup =
  (reqData: any, setSignup: any, setLoading: any, navigate: any) =>
    async (dispatch: Dispatch<SignUpAction>): Promise<any> => {
      try {
        // const navigate = useNavigate()
        dispatch({ type: AuthTypes.SIGNUP_REQ });
        const { data } = await signUp_API(reqData);
        if (data.code) {
          setSignup(false)
          setLoading(false)

          navigate('/verify-email', {
            state : {data : reqData?.email}
          })
        }
        console.log(data);
        
        dispatch({ type: AuthTypes.SIGNUP_SUC, payload: data });

      } catch (error: any) {
        setLoading(false)
        dispatch({
          type: AuthTypes.SIGNUP_FAIL,
          error: error?.response?.data?.error,
        });

        return Swal({
          title: "Sorry, A user exist with this email !",
          dangerMode: false,
        })
      }
    };
