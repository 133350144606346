import { toast } from "react-toastify";

export default class ErrorService {
  static handleResponse(response: any) {
    
    if (response && response.code){
        ErrorService.sendWarnMessage(response.message);
        return null;
    }
    // if (response && response.status >= 400 && response.status <= 499) {
    //   if (response.status === 409) {
    //     ErrorService.sendWarnMessage(response.data);
    //     return null;
    //   }
    //   ErrorService.sendErrorMessage(response.data);
    //   return null;
    // }
    // if (response.status === 500) {
    //   ErrorService.sendErrorMessage(response.data);
    //   return null;
    // }
    // if (
    //   response.data.code ||
    //   response.data.message === "please join the waitlist" ||
    //   response.data.message === "you are in waiting list"
    // ) {
    //   return response.data.result;
    // } else {
    //   ErrorService.sendErrorMessage(response.data);
    //   return null;
    // }
  }
  static displayAlert(message = "Something went wrong", type: any) {
    console.log(message);
    
    toast['success'](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  static logError(msg: string) {
    this.displayAlert(msg, "error");
  }
  static sendErrorMessage(data: any) {
    if (!data.message.includes("invalid user"))
      this.displayAlert(data.message || data.non_field_errors, "error");
  }
  static sendWarnMessage(message: any) {
    this.displayAlert(message, "warn");
  }
  static sendOkMessage(data: any) {
    this.displayAlert(data.message, "success");
  }
  static sendFileLimitExceededError(message:any){
       showErrorToast(message)
  }
}


export const showErrorToast = (message: string) => {
  toast['error'](message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}

export const showSuccessToast = (message: string) => {
  toast['success'](message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}