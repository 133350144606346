// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetEmail_ResetEmail__rOFVQ{
    width: 70%;

}

.ResetEmail_ResetEmail__rOFVQ > h3{
    color: #fff;
}

.ResetEmail_reset_pass_card__Tjx5H{
    width: 100%;
    padding: 4rem 0;
}

.ResetEmail_reset_pass_card__Tjx5H > div > h6{
    color: #fff!important;
    margin-bottom: 2rem;
}

.ResetEmail_reset_pass_card__Tjx5H  > h6{
    color: #fff!important;
    margin-bottom: 2rem;
}

.ResetEmail_inputContainer__XKrNb {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.ResetEmail_title__umwRD {
    color: #2B3674;
    font-weight: 600;
}
.ResetEmail_infoLabel__fuWv5 {
    font-size: 15px;
    color: #fff;
    margin: 6px 5px;
    font-weight: bold;
}

.ResetEmail_resetInputField__LBITu {
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    outline: 1px solid rgba(205, 209, 238, 0.92);
    border: 0px solid;
    color: #272727;
    background: #fff;
}

`, "",{"version":3,"sources":["webpack://./src/components/ResetEmail/ResetEmail.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;;AAEd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,WAAW;IACX,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,4CAA4C;IAC5C,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".ResetEmail{\n    width: 70%;\n\n}\n\n.ResetEmail > h3{\n    color: #fff;\n}\n\n.reset_pass_card{\n    width: 100%;\n    padding: 4rem 0;\n}\n\n.reset_pass_card > div > h6{\n    color: #fff!important;\n    margin-bottom: 2rem;\n}\n\n.reset_pass_card  > h6{\n    color: #fff!important;\n    margin-bottom: 2rem;\n}\n\n.inputContainer {\n    display: flex;\n    flex-direction: column;\n    margin-top: 10px;\n}\n\n.title {\n    color: #2B3674;\n    font-weight: 600;\n}\n.infoLabel {\n    font-size: 15px;\n    color: #fff;\n    margin: 6px 5px;\n    font-weight: bold;\n}\n\n.resetInputField {\n    width: 100%;\n    padding: 10px 20px;\n    border-radius: 10px;\n    outline: 1px solid rgba(205, 209, 238, 0.92);\n    border: 0px solid;\n    color: #272727;\n    background: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResetEmail": `ResetEmail_ResetEmail__rOFVQ`,
	"reset_pass_card": `ResetEmail_reset_pass_card__Tjx5H`,
	"inputContainer": `ResetEmail_inputContainer__XKrNb`,
	"title": `ResetEmail_title__umwRD`,
	"infoLabel": `ResetEmail_infoLabel__fuWv5`,
	"resetInputField": `ResetEmail_resetInputField__LBITu`
};
export default ___CSS_LOADER_EXPORT___;
