import { CreateQuestionsAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { askQuestion_API } from "../../../api/notebookAPI";

export const createQuestion =
  (
    entity_id: string,
    question: string,
    entity_type: string,
    audioRef: any,
    chat_id?: string
  ) =>
  async (dispatch: Dispatch<CreateQuestionsAction>): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.CREATE_QUESTION_REQ });
      audioRef.play();
      const { data } = await askQuestion_API(
        entity_id,
        question,
        entity_type,
        chat_id
      );
      dispatch({ type: NotebookTypes.CREATE_QUESTION_SUC, payload: data });
      audioRef.play();
      return data.result.data;
    } catch (error: any) {
      audioRef.play();
      dispatch({
        type: NotebookTypes.CREATE_QUESTION_FAIL,
        error: error?.response,
      });
      return "failed";
    }
  };
