import { SelectedDocState } from "../../../models";
import { UpdateSelectedDocAction } from "../../action-models";
import { CustomTypes } from "../../action-types";

export const selectedDocumentReducer = (
  state: SelectedDocState = { selectedUploaddata: null },
  action: UpdateSelectedDocAction
): SelectedDocState => {
  switch (action.type) {
    case CustomTypes.UPDATE_SELECTED_DOCUMENT:
      return {
        selectedUploaddata: action.payload,
      };

    default:
      return state;
  }
};
