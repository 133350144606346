import { CheckDocumentStatussAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { recheck_Failed_Doc_Status_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";

export const reCheckDocumentStatus =
  (document_id: string) =>
  async (
    dispatch: Dispatch<CheckDocumentStatussAction>,
    getState: any
  ): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.CHECK_DOCUMENT_STATUS_REQ });

      const { data } = await recheck_Failed_Doc_Status_API(document_id);

      let processed = getState().myUploads?.uploadData?.result?.data?.map(
        (el: any) => {
          if (el?.document_id === document_id) {
            el.processing_status = 0;
          }
          return el;
        }
      );
      if (processed) {
        getState().myUploads.uploadData.result.data = processed;
        dispatch({
          type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
          payload: getState().myUploads.uploadData,
        });
      }

      let processed_doc =
        getState().allDocuments?.documentData?.result?.data?.map((el: any) => {
          if (el?.document_id === document_id) {
            el.processing_status = 0;
          }
          return el;
        });
      if (processed_doc) {
        getState().allDocuments.documentData.result.data = processed_doc;
        dispatch({
          type: NotebookTypes.GET_ALL_DOCUMENTS_SUC,
          payload: getState().allDocuments?.documentData,
        });
      }

      return "true";
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: NotebookTypes.CHECK_DOCUMENT_STATUS_FAIL,
        error: error?.response?.data?.detail,
      });
      return "true";
    }
  };
