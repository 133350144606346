import { UpdateSelectedNotebookAction } from "../../action-models";
import { CustomTypes } from "../../action-types";
import { NotebookData } from "../../../models";
import { Dispatch } from "react";

export const updateSelectedNotebook =
  (selectedNote: NotebookData | any) =>
  async (dispatch: Dispatch<UpdateSelectedNotebookAction>): Promise<any> => {
    console.log('here', selectedNote);
    
    try {
      dispatch({
        type: CustomTypes.UPDATE_SELECTED_NOTEBOOK,
        payload: selectedNote,
      });
    } catch (error: any) {
      console.log(error);
    }
  };