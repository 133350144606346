import buildClient from "./buildClient";
import { SignUpDataObj,LoginDataObj ,SentEmailResetPassDataObj,ResetPasswordObj} from "../models";
import { Authconfig, config } from "./config";
const account = "accounts";



export const signUp_API = (data: any) => {
    return buildClient().post<SignUpDataObj>(
      `${account}/signup/`,
      data,
      config
    );
  };
  

export const login_API = (data:any) =>{
     return buildClient().post<LoginDataObj>(
       `${account}/login/`,
       data,
       config
     )
}
export const google_login_API = (data:any) =>{
     return buildClient().post<LoginDataObj>(
      `${account}/google/signin/`,
       data,
       config

     )
}
export const sent_reset_password_email_api = (data:any)=>{
       return buildClient().post<SentEmailResetPassDataObj>(
        `${account}/resetPassword/`,
         data,
         config
       )
}
export const reset_password_api = (data:any,uid:any,token:any)=>{
        return buildClient().post<ResetPasswordObj>(
          `${account}/reset-password/${uid}/${token}/`,
           data,
           config
        )
}

export const verify_otp_api = (data:any)=>{
  return buildClient().post<ResetPasswordObj>(
   `${account}/verifyotp/`,
    data,
    config
  )
}