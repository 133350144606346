import { CheckDocumentStatusState } from "../../../models";
import { CheckDocumentStatussAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const checkDocumentStatusReducer = (
  state: CheckDocumentStatusState = { documentData: null, loading: false, error: null },
  action: CheckDocumentStatussAction
): CheckDocumentStatusState => {
  switch (action.type) {
    case NotebookTypes.CHECK_DOCUMENT_STATUS_REQ:
      return {
        loading: true,
        documentData: null,
        error: null,
      };
    case NotebookTypes.CHECK_DOCUMENT_STATUS_SUC:
      return {
        loading: false,
        error: null,
        documentData: action.payload,
      };
    case NotebookTypes.CHECK_DOCUMENT_STATUS_FAIL:
      return {
        loading: false,
        error: action.error,
        documentData: null,
      };

    default:
      return state;
  }
};
