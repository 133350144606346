import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './components/Common/styles/Global.css'
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store'
import ReactGA from 'react-ga4'

const { REACT_APP_PROD_GOOGLE_ANALATICS_ID } = process.env;

ReactGA.initialize(REACT_APP_PROD_GOOGLE_ANALATICS_ID!);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);

