import { v4 as uuidv4 } from "uuid";
// ...

// Helper function to generate a device ID
export const generateDeviceId = (): string => {
  // Generate a new UUID (version 4)
  const deviceId = uuidv4();

  // Return the generated device ID
  return deviceId;
};

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const setCookie = (name: string, value: string, days: number): void => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);
  const cookieValue = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieValue;
};
