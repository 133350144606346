import {LoginState} from '../../../models';
import {LoginAction} from "../../action-models";
import {AuthTypes}  from '../../action-types';

export const userLoginReducer = (
    state: LoginState = { loginData: null, loading: false, error: null },
  action: LoginAction
):LoginState =>{
    switch (action.type) {
        case AuthTypes.LOGIN_REQ:
          return {
            loading: true,
            loginData: null,
            error: null,
          };
        case AuthTypes.LOGIN_SUC:
          console.log("success")
          return {
            loading: false,
            error: null,
            loginData: action.payload,
          };
        case AuthTypes.LOGIN_FAIL:
          return {
            loading: false,
            error: action.error,
            loginData: null,
          };
    
        default:
          return state;
      }
}