import { UpdateNotebooksAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { updateNotebook_API } from "../../../api/notebookAPI";
import ErrorService from "../../../helpers/ErrorHandler";

export const updateNotebook =
  (notebook_id: string, notebook_name: string) =>
  async (dispatch: Dispatch<UpdateNotebooksAction>): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.UPDATE_NOTEBOOKS_REQ });
      const { data } = await updateNotebook_API(notebook_id, notebook_name);

      console.log(data);
      dispatch({ type: NotebookTypes.UPDATE_NOTEBOOKS_SUC, payload: data });
      ErrorService.handleResponse(data);
      return data.result.data;
    } catch (error: any) {
      dispatch({
        type: NotebookTypes.UPDATE_NOTEBOOKS_FAIL,
        error: error?.response?.data?.error,
      });
      return "failed";
    }
  };
