/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { PiSignOutBold } from "react-icons/pi";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

import "./style.css";
import { Get_Subscription_Details } from "../../api/paymentsAPI";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { FaUserAlt } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";

const UserAvatar = () => {
  const [subs, setSubs] = useState<any>({});
  const navigate = useNavigate();
  const { userdata }: any = useTypedSelector((state) => state.user);

  const handleLogout = (): void => {
    if (window !== undefined) {
      localStorage.removeItem("userInfo");
      navigate("/");
      window.location.reload();
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Checking the user subscription
  useEffect(() => {
    return () => {
      if (userdata?.token?.access) {
        Get_Subscription_Details()
          .then((it) => {
            setSubs(it.data);
          })
          .catch((it) => {
            console.error(it);
          });
      }
    };
  }, [open]);


  return (
    <div className="user_avatar_container">
      <div className="avatar" onClick={handleClick}>
        <span className="user_avatar_icon">
          <FaUserAlt />
        </span>
        {/* <span className="user_avatar_name">{userdata?.user?.name ?? "User"}</span> */}
        {/* <span className="avatar_chevron">
          {open ? <BiChevronUp /> : <BiChevronDown />}
        </span> */}
      </div>
      <Paper>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            sx={{
              width: "200px",
            }}
          >
            <ListItemIcon>
              <CiUser fontSize="large" />
            </ListItemIcon>
            <ListItemText>{userdata?.user?.name}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <LiaMoneyCheckAltSolid fontSize="large" />
            </ListItemIcon>
            <Link style={{color: '#000000DE'}} to={'/plans'}><ListItemText>Plan - {subs?.plan_id?.split('-')[0]}</ListItemText></Link>

          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <PiSignOutBold fontSize="large" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Paper>
    </div>
  );
};

export default UserAvatar;
