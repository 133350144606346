import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./helpers/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Auth from "./pages/Auth/Auth";
import ResetPass from "./pages/ResetPass/ResetPass";
import ChangePass from "./components/ChangePass/ChangePass";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LazyLoadingComp from "./components/Common/LazyLoadingComp";
import Main from "./pages/main";
import ErrorFallback from "./components/Fallback/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import VerifyOtp from "./pages/verify-otp";
import { generateDeviceId, getCookie, setCookie } from "./utils/deviceUtils";

const HomePage = lazy(() => import("./pages/Home"));
const LazyLandingPage = lazy(() => import("./pages/homePage"));
const LazyAllDocumentsPage = lazy(() => import("./pages/AllDocuments"));
const LazyNotebooks = lazy(() => import("./pages/notebooks"));
const LazySharedNotebooks = lazy(() => import("./pages/sharedNotebook"));
const LazyPricingPage = lazy(() => import("./components/PricingCards"));
const DEVICE_ID_COOKIE_NAME = process.env.REACT_APP_DEVICE_ID_COOKIE_NAME || "";


function App() {

  useEffect(() => {
    const deviceIdCookie = getCookie(DEVICE_ID_COOKIE_NAME);
   
    if (!deviceIdCookie) {
      const newDeviceId = generateDeviceId();
      setCookie(DEVICE_ID_COOKIE_NAME, newDeviceId, 365);

    
    }
    
  }, []);
  return (
    <>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
      >
        <div className="App">
          <ToastContainer />
          <Suspense fallback={<LazyLoadingComp />}>
            <Routes>
              <Route path={routes.auth} element={<Auth />} />
              <Route path={routes.recoverPassword} element={<ResetPass />} />
              <Route path={routes.verifyEmail} element={<VerifyOtp />} />
              <Route
                path="/reset-password/:uid/:token"
                element={<ChangePass />}
              />

              <Route path={routes.test} element={<HomePage />} />
              {/* <Route path={routes.root} element={<UploadDocument />} /> */}
              {/* <Route path={routes.root} element={<LandingPage />} /> */}
              <Route
                path={routes.root}
                element={
                  <Main>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => {
                              window.location.replace("/");
                            }}
                          >
                            <Suspense fallback="loading...">
                              <LazyLandingPage />
                            </Suspense>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path={routes.documents}
                        element={
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => { 
                              window.location.replace("/");
                            }}
                          >
                            <Suspense fallback="loading...">
                              <LazyAllDocumentsPage />
                            </Suspense>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path={routes.notebooks}
                        element={
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => {
                              window.location.replace("/");
                            }}
                          >
                            <Suspense fallback="loading...">
                              <LazyNotebooks />
                            </Suspense>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path={routes.plans}
                        element={
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => {
                              window.location.replace("/");
                            }}
                          >
                            <Suspense fallback="loading...">
                              <LazyPricingPage />
                            </Suspense>
                          </ErrorBoundary>
                        }
                      />
                      <Route
                        path={routes.sharedNotebook}
                        element={
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            onReset={() => {
                              window.location.replace("/");
                            }}
                          >
                            <Suspense fallback="loading...">
                              <LazySharedNotebooks />
                            </Suspense>
                          </ErrorBoundary>
                        }
                      />
                    </Routes>
                  </Main>
                }
              />

              {/* <Route path={routes.user} element={<Dashboard />} /> */}

              <Route path="*" element={<h1>Page not found</h1>} />
            </Routes>
          </Suspense>
        </div>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
