export const routes = {
  root: "/*",
  login: "/login",
  uplaodDocumentPage: "/uplaodDocument",
  auth: "/auth",
  recoverPassword: "/recover-password",
  verifyEmail : "/verify-email",
  test: "/test",
  plans: "/plans",
  notebooks: "/notebooks",
  documents: "/documents",
  sharedNotebook: "/shared/:notebook_id",
};
