import { CheckDocumentStatussAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { checkDocumentStatus_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";

export const checkDocumentStatus =
  (document_id: string) =>
  async (
    dispatch: Dispatch<CheckDocumentStatussAction>,
    getState: any
  ): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.CHECK_DOCUMENT_STATUS_REQ });

      const { data } = await checkDocumentStatus_API(document_id);

      let processed = getState().myUploads?.uploadData?.result?.data?.map(
        (el: any) => {
          if (el?.document_id === data?.result?.data?.document_id) {
            el.processing_status = data.result?.data?.processing_status;
            el.document_link = data.result?.data?.document_link;
          }
          return el;
        }
      );

      if (processed) {
        getState().myUploads.uploadData.result.data = processed;
        dispatch({
          type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
          payload: getState().myUploads.uploadData,
        });
      }

      let processed_doc = getState().allDocuments.documentData?.result?.data?.map(
        (el: any) => {
          if (el?.document_id === data?.result?.data?.document_id) {
            el.processing_status = data.result?.data?.processing_status;
            el.document_link = data.result?.data?.document_link;
          }
          return el;
        }
      );
      if (processed_doc) {
        getState().allDocuments.documentData.result.data = processed_doc;

        dispatch({
          type: NotebookTypes.GET_ALL_DOCUMENTS_SUC,
          payload: getState().allDocuments?.documentData,
        });
      }

      if (data?.result?.data?.processing_status === -1) {
        Swal({
          title: "File Upload failed !",
          text: "File upload failed, Please try again.",
          dangerMode: true,
        });
        return "true";
      }
      if (data?.result?.data?.processing_status < 9) return "false";

      dispatch({
        type: NotebookTypes.CHECK_DOCUMENT_STATUS_SUC,
        payload: data,
      });

      return "true";
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: NotebookTypes.CHECK_DOCUMENT_STATUS_FAIL,
        error: error?.response?.data?.detail,
      });
      return "true";
    }
  };
