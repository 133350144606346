import { CreateNotebooksAction } from "../../action-models";
import { NotebookTypes, CustomTypes } from "../../action-types";
import { Dispatch } from "react";
import { createNotebooks_API } from "../../../api/notebookAPI";
import ErrorService from "../../../helpers/ErrorHandler";
import Swal from "sweetalert";

import { uniqueNamesGenerator, colors, starWars } from "unique-names-generator";

export const createNotebook =
  (navigate : any, updateSelected: boolean = false) =>
  async (
    dispatch: Dispatch<CreateNotebooksAction>,
    getState: any
  ): Promise<any> => {
    try {
      
      dispatch({ type: NotebookTypes.CREATE_NOTEBOOKS_REQ });

      const notebook_name: string = uniqueNamesGenerator({
        dictionaries: [starWars, colors],
        style: "lowerCase",
        separator: " ",
      });

      const { data } = await createNotebooks_API(notebook_name);

      getState().myNotebooks?.notebookData?.result?.data?.unshift(
        data.result.data
      );
      dispatch({ type: NotebookTypes.CREATE_NOTEBOOKS_SUC, payload: data });
      dispatch({
        type: NotebookTypes.GET_MY_NOTEBOOKS_SUC,
        payload: getState().myNotebooks.notebookData,
      });

      if (updateSelected) {
        dispatch({
          type: CustomTypes.UPDATE_SELECTED_NOTEBOOK,
          payload: data.result.data,
        });
      }

      ErrorService.handleResponse(data);

      return data.result.data
    } catch (error: any) {
      console.log(error);
      // Show an alert if user is not looged in and trying to upload more than 1 notebook
      if (error?.response?.status === 401) {
        console.log("In Authorization");
        Swal({
          title: "Feature not available",
          text: "This feature is not available with your current plan. Please visit the Plans page to subscribe to the relevant plan",
          dangerMode: true,
          buttons: ["Stay here", "Signup / Sign in"],
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = "/auth";
          }
        });
      }
      // Show an alert if session was ended
      else if (error?.response?.data?.code === "token_not_valid") {
        Swal({
          title: "Your session was expired! You have to login again.",
          dangerMode: false,
        }).then(() => {
          localStorage.removeItem("userInfo");
          navigate("/auth");
        });
      }
      // Shows an alert if the user has exceed the notebook creation according to the subscription plan
      else if (error?.response?.status === 403) {
        Swal({
          title: "Feature not available",
          text: "This feature is not available with your current plan. Please visit the Plans page to subscribe to the relevant plan",
          dangerMode: true,
          buttons: ["Stay here", "Go to plans"],
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = "/plans";
          }
        });
      } else {
        Swal({
          title: "Login failed! Please try again.",
          dangerMode: false,
        });
      }

      dispatch({
        type: NotebookTypes.CREATE_NOTEBOOKS_FAIL,
        error: error?.response?.data?.detail,
      });
      return false;
    }
  };
