import { userInfo } from "../redux";
import { getCookie } from "../utils/deviceUtils";

import React from 'react'
import { useTypedSelector } from "../hooks/useTypedSelector";
// let token = userInfo?.token?.access;



export const config: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const Authconfig: any = {
  headers: {
    // Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const AuthFormconfig: any = {
  headers: {
    // Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
};

export const SignupFormConfig: any = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const Formconfig: any = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};


export const ChargeBeeConfig: any = {
  headers: {
    'Authorization': 'Basic ' + btoa('test_64AfnLRkwX5QnlWOhZJk1wfNVQdTJNiW' + ':' + 'password')
  },
}
export const deviceAth: any = config;
export const deviceFormAuth: any = Formconfig;
