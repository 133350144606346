import { DeleteNotebooksAction } from "../../action-models";
import { CustomTypes, NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { delete_notebook_API } from "../../../api/notebookAPI";
import { UploadsData } from "../../../models";
import ErrorService from "../../../helpers/ErrorHandler";
import Swal from "sweetalert";

export const deleteNotebook =
  (notebook_id: string) =>
  async (
    dispatch: Dispatch<DeleteNotebooksAction>,
    getState: any
  ): Promise<any> => {
    try {
      dispatch({ type: NotebookTypes.DELETE_NOTEBOOK_REQ });

      if (getState().myNotebooks?.notebookData?.result?.data?.length === 1) {
        Swal({
          title: "Cannot delete the last notebook",
          text: "You cannot delete all notebooks. Atleast one notebook should be there.",
          dangerMode: true,
        });
        return;
      }

      const { data } = await delete_notebook_API(notebook_id);

      let undeleted_notebooks =
        getState().myNotebooks.notebookData.result.data.filter(
          (el: any) => el.notebook_id !== notebook_id
        );

      getState().myNotebooks.notebookData.result.data = undeleted_notebooks;

      dispatch({
        type: NotebookTypes.GET_MY_NOTEBOOKS_SUC,
        payload: getState().myNotebooks.notebookData,
      });

      dispatch({
        type: CustomTypes.UPDATE_SELECTED_NOTEBOOK,
        payload: undeleted_notebooks[0],
      });

      return "true";
    } catch (error: any) {
      console.log(error?.response?.status);
      if(error?.response?.status === 403){
        Swal({
          title: "Cannot delete notebook",
          text: error?.response?.data?.detail,
          dangerMode: true,
        });
      }
      dispatch({
        type: NotebookTypes.DELETE_NOTEBOOK_FAIL,
        error: error?.response?.data?.detail,
      });

      return "false";
    }
  };
