export enum NotebookTypes {
  GET_MY_NOTEBOOKS_REQ = "GET_MY_NOTEBOOKS_REQ",
  GET_MY_NOTEBOOKS_SUC = "GET_MY_NOTEBOOKS_SUC",
  GET_MY_NOTEBOOKS_FAIL = "GET_MY_NOTEBOOKS_FAIL",

  
  GET_NOTEBOOK_REQ = "GET_NOTEBOOK_REQ",
  GET_NOTEBOOK_SUC = "GET_NOTEBOOK_SUC",
  GET_NOTEBOOK_FAIL = "GET_NOTEBOOK_FAIL",

  COPY_NOTEBOOK_REQ = "COPY_NOTEBOOK_REQ",
  COPY_NOTEBOOK_SUC = "COPY_NOTEBOOK_SUC",
  COPY_NOTEBOOK_FAIL = "COPY_NOTEBOOK_FAIL",

  CREATE_NOTEBOOKS_REQ = "CREATE_NOTEBOOKS_REQ",
  CREATE_NOTEBOOKS_SUC = "CREATE_NOTEBOOKS_SUC",
  CREATE_NOTEBOOKS_FAIL = "CREATE_NOTEBOOKS_FAIL",

  
  UPDATE_NOTEBOOKS_REQ = "UPDATE_NOTEBOOKS_REQ",
  UPDATE_NOTEBOOKS_SUC = "UPDATE_NOTEBOOKS_SUC",
  UPDATE_NOTEBOOKS_FAIL = "UPDATE_NOTEBOOKS_FAIL",
  
  CREATE_QUESTION_REQ = "CREATE_QUESTION_REQ",
  CREATE_QUESTION_SUC = "CREATE_QUESTION_SUC",
  CREATE_QUESTION_FAIL = "CREATE_QUESTION_FAIL",

  GET_MY_DOCUMENTS_REQ = "GET_MY_DOCUMENTS_REQ",
  GET_MY_DOCUMENTS_SUC = "GET_MY_DOCUMENTS_SUC",
  GET_MY_DOCUMENTS_FAIL = "GET_MY_DOCUMENTS_FAIL",


  GET_ALL_DOCUMENTS_REQ = "GET_ALL_DOCUMENTS_REQ",
  GET_ALL_DOCUMENTS_SUC = "GET_ALL_DOCUMENTS_SUC",
  GET_ALL_DOCUMENTS_FAIL = "GET_ALL_DOCUMENTS_FAIL",


  CHECK_DOCUMENT_STATUS_REQ = "CHECK_DOCUMENT_STATUS_REQ",
  CHECK_DOCUMENT_STATUS_SUC = "CHECK_DOCUMENT_STATUS_SUC",
  CHECK_DOCUMENT_STATUS_FAIL = "CHECK_DOCUMENT_STATUS_FAIL",

  GET_CHAT_HISTORY_REQ = "GET_CHAT_HISTORY_REQ",
  GET_CHAT_HISTORY_SUC = "GET_CHAT_HISTORY_SUC",
  GET_CHAT_HISTORY_FAIL = "GET_CHAT_HISTORY_FAIL", 

  UPLOAD_DOCUMENT_REQ = "UPLOAD_DOCUMENT_REQ",
  UPLOAD_DOCUMENT_SUC = "UPLOAD_DOCUMENT_SUC",
  UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL",

  
  DELETE_NOTEBOOK_REQ = "DELETE_NOTEBOOK_REQ",
  DELETE_NOTEBOOK_SUC = "DELETE_NOTEBOOK_SUC",
  DELETE_NOTEBOOK_FAIL = "DELETE_NOTEBOOK_FAIL",

  DELETE_DOCUMENT_REQ = "DELETE_DOCUMENT_REQ",
  DELETE_DOCUMENT_SUC = "DELETE_DOCUMENT_SUC",
  DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL",

  REMOVE_DOCUMENT_REQ = "REMOVE_DOCUMENT_REQ",
  REMOVE_DOCUMENT_SUC = "REMOVE_DOCUMENT_SUC",
  REMOVE_DOCUMENT_FAIL = "REMOVE_DOCUMENT_FAIL",
}
