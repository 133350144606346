import { combineReducers } from "redux";

import {
  getMyUploadsReducer,
  uploadsDocumentReducer,
  getMyNotebooksReducer,
  createNotebookReducer,
  createQuestionReducer,
  getChatHistoryReducer,
  updateNotebookReducer,
  checkDocumentStatusReducer,
  deleteDocumentReducer,
  allDocumentReducer,
} from "./notebooks/index";
import {
  selectedDocumentReducer,
  selectedNotebookReducer,
} from "./custom/index";
import {
  userSignupReducer,
  userLoginReducer,
  resetPasswordLinkReducer,
  userReducer,
} from "./auth/index";

const userInfoFromStorage: any = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")!)
  : null;

export const userInfo = userInfoFromStorage;

const initailStat = {
  user: { userInfoFromStorage },
};

const reducers = combineReducers({
  myUploads: getMyUploadsReducer,
  myNotebooks: getMyNotebooksReducer,
  chatHistory: getChatHistoryReducer,
  createNotebook: createNotebookReducer,
  createQuestion: createQuestionReducer,
  selectedDocument: selectedDocumentReducer,
  selectedNotebook: selectedNotebookReducer,
  uploadDocument: uploadsDocumentReducer,
  userSignup: userSignupReducer,
  userLogin: userLoginReducer,
  user: userReducer,
  resetPasswordLink: resetPasswordLinkReducer,
  updateNote: updateNotebookReducer,
  documentStatus: checkDocumentStatusReducer,
  deleteDocuement: deleteDocumentReducer,
  allDocuments: allDocumentReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
