import React from "react";
import { Link } from "react-router-dom";

import { FaMoneyCheckDollar } from "react-icons/fa6";

import "./style.css";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import UserAvatar from "../Avatar/UserAvatar";
import logo from "../../assets/images/updated_logo.png";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { FaUserAlt } from "react-icons/fa";

interface SidebarProps {
  active?: string;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const Sidebar = React.memo(({ active }: SidebarProps) => {

  const { userdata }: any = useTypedSelector((state) => state.user);

  return (
    <div className="sidebar_container">
      <aside className="sidebar">
        <Link to="/">
          <div className="header_logo">
            <span className="icon">
              <img src={logo} alt="" />
            </span>
            {/* <span className="title">PDFBot.ai</span> */}
          </div>
        </Link>
        <Link
          to="/"
          className={active === "home" ? "sidebar_link_active" : "sidebar_link"}
        >
          <CustomWidthTooltip title="Home">
            <span className="sidebar_icon">
              {active === "home" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M15.0602 2.69839C14.7574 2.46184 14.3842 2.33334 14 2.33334C13.6158 2.33334 13.2426 2.46184 12.9398 2.69838L4.17262 9.54775C3.74811 9.8794 3.5 10.3881 3.5 10.9268V24.5H11.6667V15.1667H16.3333V24.5H24.5V10.9268C24.5 10.3881 24.2519 9.8794 23.8274 9.54775L15.0602 2.69839Z"
                    fill="#FFFEFE"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M15.0602 2.69839C14.7574 2.46184 14.3842 2.33334 14 2.33334C13.6158 2.33334 13.2426 2.46184 12.9398 2.69838L4.17262 9.54775C3.74811 9.8794 3.5 10.3881 3.5 10.9268V24.5H11.6667V15.1667H16.3333V24.5H24.5V10.9268C24.5 10.3881 24.2519 9.8794 23.8274 9.54775L15.0602 2.69839Z"
                    fill="#939496"
                  />
                </svg>
              )}
            </span>
          </CustomWidthTooltip>
        </Link>
        
        {/* {userdata?.token?.access && ( */}
          <Link
            to="/documents"
            className={
              active === "documents" ? "sidebar_link_active" : "sidebar_link"
            }
          >
            <CustomWidthTooltip title="All Documents">
              <span className="sidebar_icon">
                {active === "documents" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M24.57 13.79L24.3833 13.5333C24.0567 13.1367 23.6717 12.8217 23.2283 12.5883C22.6333 12.25 21.9567 12.075 21.2567 12.075H6.73167C6.03167 12.075 5.36667 12.25 4.76001 12.5883C4.30501 12.8333 3.89667 13.1717 3.55834 13.5917C2.89334 14.4433 2.57834 15.4933 2.68334 16.5433L3.11501 21.9917C3.26667 23.6367 3.46501 25.6667 7.16334 25.6667H20.8367C24.535 25.6667 24.7217 23.6367 24.885 21.98L25.3167 16.555C25.4217 15.575 25.165 14.595 24.57 13.79ZM16.7883 20.23H11.2C10.745 20.23 10.3833 19.8567 10.3833 19.4133C10.3833 18.97 10.745 18.5967 11.2 18.5967H16.7883C17.2433 18.5967 17.605 18.97 17.605 19.4133C17.605 19.8683 17.2433 20.23 16.7883 20.23Z"
                      fill="#D9D9D9"
                    />
                    <path
                      d="M24.001 10.1711C24.0335 10.5544 23.6193 10.8114 23.2578 10.6799C22.6286 10.4511 21.9628 10.3367 21.2684 10.3367H6.73169C6.03353 10.3367 5.34694 10.4581 4.71262 10.6888C4.35533 10.8187 3.94336 10.5717 3.94336 10.1915V7.76999C3.94336 3.60499 5.21503 2.33333 9.38003 2.33333H10.7567C12.425 2.33333 12.95 2.86999 13.6267 3.74499L15.0267 5.61166C15.3184 6.00833 15.33 6.03166 15.8434 6.03166H18.62C22.3232 6.03166 23.7352 7.03895 24.001 10.1711Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M24.57 13.79L24.3833 13.5333C24.0567 13.1367 23.6717 12.8217 23.2283 12.5883C22.6333 12.25 21.9567 12.075 21.2567 12.075H6.73167C6.03167 12.075 5.36667 12.25 4.76001 12.5883C4.30501 12.8333 3.89667 13.1717 3.55834 13.5917C2.89334 14.4433 2.57834 15.4933 2.68334 16.5433L3.11501 21.9917C3.26667 23.6367 3.46501 25.6667 7.16334 25.6667H20.8367C24.535 25.6667 24.7217 23.6367 24.885 21.98L25.3167 16.555C25.4217 15.575 25.165 14.595 24.57 13.79ZM16.7883 20.23H11.2C10.745 20.23 10.3833 19.8567 10.3833 19.4133C10.3833 18.97 10.745 18.5967 11.2 18.5967H16.7883C17.2433 18.5967 17.605 18.97 17.605 19.4133C17.605 19.8683 17.2433 20.23 16.7883 20.23Z"
                      fill="#939496"
                    />
                    <path
                      d="M24.001 10.1711C24.0335 10.5544 23.6193 10.8114 23.2578 10.6799C22.6286 10.4511 21.9628 10.3367 21.2684 10.3367H6.73169C6.03353 10.3367 5.34694 10.4581 4.71262 10.6888C4.35533 10.8187 3.94336 10.5717 3.94336 10.1915V7.76999C3.94336 3.60499 5.21503 2.33333 9.38003 2.33333H10.7567C12.425 2.33333 12.95 2.86999 13.6267 3.74499L15.0267 5.61166C15.3184 6.00833 15.33 6.03166 15.8434 6.03166H18.62C22.3232 6.03166 23.7352 7.03895 24.001 10.1711Z"
                      fill="#939496"
                    />
                  </svg>
                )}
              </span>
            </CustomWidthTooltip>
          </Link>

          <Link
          to="/notebooks"
          className={
            active === "notebooks" ? "sidebar_link_active" : "sidebar_link"
          }
        >
          <CustomWidthTooltip title="Notebooks">
            <span className="sidebar_icon">
              {active === "notebooks" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M16.9166 7.24434L5.68364 3.5L23.3333 3.5V9.69577L21 12.0291V23.3333H16.9166V7.24434Z"
                    fill="#FFFEFE"
                  />
                  <path
                    d="M4.66665 5.00567L15.1666 8.50567L15.1666 25.4223L5.86325 22.3212C5.14865 22.083 4.66665 21.4143 4.66665 20.661V5.00567Z"
                    fill="#FFFEFE"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M16.9166 7.24434L5.68364 3.5L23.3333 3.5V9.69577L21 12.0291V23.3333H16.9166V7.24434Z"
                    fill="#939496"
                  />
                  <path
                    d="M4.66665 5.00567L15.1666 8.50567L15.1666 25.4223L5.86325 22.3212C5.14865 22.083 4.66665 21.4143 4.66665 20.661V5.00567Z"
                    fill="#939496"
                  />
                </svg>
              )}
            </span>
          </CustomWidthTooltip>
          {/* <p className="sidebar_title">Notebooks</p> */}
        </Link>
        {/* )} */}
        {/* <Link
          to="/me"
          className={
            active === "dashboard" ? "sidebar_link_active" : "sidebar_link"
          }
        >
          <CustomWidthTooltip title="Dashboard">
            <span className="plans_icon">
              <RxDashboard />
            </span>
          </CustomWidthTooltip>
        </Link> */}
        <div className="sidebar_footer">
          
          <Link
            to="/plans"
            className={
              active === "plans" ? "sidebar_link_active" : "sidebar_link"
            }
          >
            <CustomWidthTooltip title="Plans">
              <span className="plans_icon">
                <FaMoneyCheckDollar />
              </span>
            </CustomWidthTooltip>
          </Link>
         
          {userdata?.token?.access ? (
            <div className="header_user_avatar">
              <UserAvatar />
            </div>
          ) : (
            <Link to="/auth" className="sidebar_link">
              <CustomWidthTooltip title="Login / Signup">
                <span className="login_icon">
                  <FaUserAlt />
                </span>
              </CustomWidthTooltip>
            </Link>
          )}
        </div>
      </aside>
    </div>
  );
});

export default React.memo(Sidebar);
