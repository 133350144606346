import { UploadDocumentAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";
import { Dispatch } from "react";
import { uploadDocument_API } from "../../../api/notebookAPI";
import Swal from "sweetalert";

export const uploadDocument =
  (
    file: any,
    notebook_id: any,
    from: string,
    navigate : any,
    setProgress: any,
    pushNewData: boolean = true
  ) =>
  async (
    dispatch: Dispatch<UploadDocumentAction>,
    getState: any
  ): Promise<any> => {
    try {
      // Swal({
      //   title: "File Upload on progress !",
      //   text: "File is uploading in the background.",
      //   icon: "success",
      //   dangerMode: false,
      // });
      dispatch({ type: NotebookTypes.UPLOAD_DOCUMENT_REQ });

      const { data } = await uploadDocument_API(file, notebook_id, from, setProgress);
      dispatch({ type: NotebookTypes.UPLOAD_DOCUMENT_SUC, payload: data });

      if (pushNewData) {
        getState().myUploads.uploadData?.result?.data?.push(data.result.data);
        dispatch({
          type: NotebookTypes.GET_MY_DOCUMENTS_SUC,
          payload: getState().myUploads.uploadData,
        });
      }

      getState().allDocuments?.documentData?.result?.data?.unshift(
        data?.result?.data
      );

      dispatch({
        type: NotebookTypes.GET_ALL_DOCUMENTS_SUC,
        payload: getState().allDocuments?.documentData,
      });

      return data.result.data;
    } catch (error: any) {
      console.log(error);

      // Shows an error if user is not logged in and trying to upload more than 3 document
      if (error?.response?.status === 401) {
        Swal({
          title: "Limit exceeded",
          text: "Document upload limit exceeded ! Please SignIn / SignUp to upload more document.",
          dangerMode: true,
          buttons: ["SignIn / SignUp", "Stay here"],
        }).then((willDelete) => {
          if (!willDelete) {
            navigate("/auth")
          }
        });
      }
      // Shows an alert is user session was ended
      else if (error?.response?.data?.code === "token_not_valid") {
        Swal({
          title: "Your session was expired! You have to login again.",
          dangerMode: false,
        }).then(() => {
          localStorage.removeItem("userInfo");
          navigate("/auth")
        });
      }
      // Shows an alert is user wants to upload more document allowed by the plan
      else if (error?.response?.status === 403) {
        Swal({
          title:
            "Document upload limit exceeded ! Please upgrade to a higher subscription",
          dangerMode: true,
          buttons: ["Stay here", "Goto Plans"],

        }).then((Plans) => {
          if(Plans){
            navigate('/plans');

          }
        });
      } else {
        console.log(error);
        Swal({
          title: "Upload failed! Please try again.",
          dangerMode: false,
        });
      }
      dispatch({
        type: NotebookTypes.UPLOAD_DOCUMENT_FAIL,
        error: error?.response?.data?.error,
      });
      return "failed";
    }
  };
