import { UpdateNotebookState } from "../../../models";
import { UpdateNotebooksAction } from "../../action-models";
import { NotebookTypes } from "../../action-types";

export const updateNotebookReducer = (
  state: UpdateNotebookState = { updateNotebookData: null, loading: false, error: null },
  action: UpdateNotebooksAction
): UpdateNotebookState => {
  switch (action.type) {
    case NotebookTypes.UPDATE_NOTEBOOKS_REQ:
      return {
        loading: true,
        updateNotebookData: null,
        error: null,
      };
    case NotebookTypes.UPDATE_NOTEBOOKS_SUC:
      return {
        loading: false,
        error: null,
        updateNotebookData: action.payload,
      };
    case NotebookTypes.UPDATE_NOTEBOOKS_FAIL:
      return {
        loading: false,
        error: action.error,
        updateNotebookData: null,
      };

    default:
      return state;
  }
};
